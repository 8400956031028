.fc-col-header-cell-cushion {
  text-transform: uppercase;
  color: grey;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .fc .fc-daygrid-day-frame {
    align-items: center;
    height: 30px;
  }

  .fc-scroller {
    overflow: hidden !important;
  }

  .fc-day-today {
    background-color: inherit !important;
  }
}

@media only screen and (min-width: 601px) {

  .fc td,
  .fc th,
  .fc table {
    border-style: none !important;
  }

  .fc .fc-daygrid-day-frame {
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    height: 130px;
    border-radius: 4px;
    margin: 5px;
  }

  .fc-scroller {
    overflow: scroll !important;
    padding-left: 10px;
  }

  .fc-scroller::-webkit-scrollbar {
    display: none;
  }

  .fc-day-today {
    background-color: inherit !important;
  }
}