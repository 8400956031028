.calendar-wrapper {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: min-content;
  align-self: center;
  border-radius: 150px;
  padding: 5px;
  display: flex;
  justify-content: center;
  .hideExtraDates {
    visibility: hidden;
  }
  .backgroundcolor-red {
    height: 43px !important;
    flex: none !important;
    width: 43px !important;
    background-color: #f19999 !important;
    border-radius: 25px;
    color: white !important;
    padding-left: 14px !important;
    padding-top: 13px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    align-items: center;
    margin-right: 5px !important;
    justify-content: center;
  }

  .backgroundcolor-green {
    height: 43px !important;
    flex: none !important;
    width: 43px !important;
    background-color: #24A59E !important;
    border-radius: 25px;
    color: white !important;
    padding-left: 14px !important;
    padding-top: 13px !important;
    margin-top: 3px;

    margin-bottom: 3px;
    align-items: center;
    margin-right: 5px !important;
    justify-content: center;
  }
  .hideBackgroundColor {
    background-color:  #A7DBD8 !important;
    height: 43px !important;
    flex: none !important;
    width: 43px !important;
    border-radius: 0px;
    color: white !important;
    padding-left: 14px !important;
    padding-top: 13px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    align-items: center;
    margin-right: 5px !important;
    justify-content: center;
    z-index: 1;
    position: relative;
    overflow: unset !important;
  }
  .redBackgroundColor {
    background-color: #A7DBD8 !important;
    height: 43px !important;
    flex: none !important;
    width: 43px !important;
    border-radius: 0px;
    color: white !important;
    padding-left: 14px !important;
    padding-top: 13px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    align-items: center;
    margin-right: 5px !important;
    justify-content: center;
    z-index: 1;
    position: relative;
    overflow: unset !important;
  }
  .redBackgroundColor:before {
    position: absolute;
    content: '';
    background: #f19999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    z-index: 1;
  }
  .redBackgroundColor:not(:last-child)::after {
    position: absolute;
    content: '';
    background: #A7DBD8;
    width: 5px;
    height: 100%;
    top: 0;
    right: -5px;
    bottom: 0;
    z-index: 1;
  }
  .yellowBackgroundColor {
    background-color: #A7DBD8 !important;
    height: 43px !important;
    flex: none !important;
    width: 43px !important;
    border-radius: 0px;
    color: white !important;
    padding-left: 14px !important;
    padding-top: 13px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    align-items: center;
    margin-right: 5px !important;
    justify-content: center;
    z-index: 1;
    position: relative;
    overflow: unset !important;
  }
  .yellowBackgroundColor:before {
    position: absolute;
    content: '';
    background: #FFCF5C;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    z-index: 1;
  }
  .yellowBackgroundColor:not(:last-child)::after {
    position: absolute;
    content: '';
    background: #A7DBD8;
    width: 5px;
    height: 100%;
    top: 0;
    right: -3px;
    bottom: 0;
    z-index: 1;
  }
  
.hideBackgroundColor:before {
  position: absolute;
  content: '';
  background: #24A59E;
  color: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: -5;
}
.hideBackgroundColor:not(:last-child)::after {
  position: absolute;
  content: '';
  background: #A7DBD8;
  width: 5px;
  height: 100%;
  top: 0;
  right: -5px;
  bottom: 0;
  z-index: 1;
}
.react-calendar__tile--rangeEnd.hideBackgroundColor::after{
  display: none;
}
.react-calendar__tile--rangeEnd.redBackgroundColor::after{
  display: none;
}

.redBackgroundColor abbr, .redBackgroundColor .availableUsers {
  z-index: 2;
  position: relative;
}
.react-calendar__tile--rangeEnd.yellowBackgroundColor::after{
  display: none;
}

.yellowBackgroundColor abbr, .yellowBackgroundColor .availableUsers {
  z-index: 2;
  position: relative;
}
.react-calendar__tile--rangeEnd.hideBackgroundColor{
  border-radius: 0 50% 50% 0 !important;
}

.react-calendar__tile--rangeStart.hideBackgroundColor{
  border-radius: 20% 0 0 50% !important;
}

.react-calendar__tile--rangeEnd.redBackgroundColor{
  border-radius: 0 50% 50% 0 !important;
}
.react-calendar__tile--rangeStart.redBackgroundColor{
  border-radius: 50% 0 0 50% !important;
}
.react-calendar__tile--rangeEnd.yellowBackgroundColor{
  border-radius: 0 50% 50% 0 !important;
}
.react-calendar__tile--rangeStart.yellowBackgroundColor{
  border-radius: 50% 0 0 50% !important;
}

.hide-background-color abbr,.hide-background-color.availableUsers {
  z-index: 2;
  position: relative;
}
  .backgroundcolor-yellow {
    height: 43px !important;
    flex: none !important;
    width: 43px !important;
    background-color: #FFCF5C !important;
    border-radius: 25px;
    color: white !important;
    padding-left: 14px !important;
    padding-top: 13px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    align-items: center !important;
    margin-right: 5px !important;
    justify-content: center !important;
  }

  .react-calendar-tile:not(.react-calendar-tile--active) {
    color: transparent;
    pointer-events: none;
  }

  .react-calendar .backgroundcolor {
    height: 43px !important;
    flex: none !important;
    width: 43px !important;
    background-color: #24A59E !important;
    border-radius: 25px;
    color: white !important;
    padding-left: 14px !important;
    padding-top: 13px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    align-items: center !important;
    margin-right: 5px !important;
    justify-content: center
  }

  .availableUsers {
    font-size: 10px;
    position: relative;
    left: 6px;
    bottom: 32px;
  }
}

.react-calendar__tile:hover {
  height: auto;
  color: white !important;
  width: 43px !important;
}

.calendar-wrapper abbr {
  text-decoration: none !important;
}

.lable-container {
  display: inline-block;
}
.datedisabled input[type="submit"]{
  text-align: left;
}
.disableddatecalender .react-calendar__month-view__days {
    font-size: 14px !important;
    flex: none !important;
    padding: 0 7px;
  }
