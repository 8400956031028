/* Custom styles for the calendar */
.calendar-wrapper {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Add backdrop shadow */
  width: min-content;
  align-self: center;
  border-radius: 15px;
  padding: 5px;
}

.calendar-wrapper abbr {
  text-decoration: none !important;
  cursor: pointer;
}


.react-calendar {
  border: none;
  /* Remove borders */
}

.react-calendar__tile--active {
  background-color: #FFF !important;
  color: black !important;
}

.react-calendar__tile:hover {
  height: auto;
  color: black;
  width: fit-content;
}

.react-calendar__tile:active {
  color: white !important;
}

.react-calendar__tile--hasActive {
  height: auto;
  color: white !important;
  width: fit-content !important;
  background-color: #24A59E !important;
  border-radius: 50%;
  display: inline-block;
}

.react-calendar__tile--now {
  background-color: white;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__month-view__days__day--weekend--active {
  color: white !important;
}


.react-calendar__tile {
  padding: 15px !important;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #d3d3d3;
  width: fit-content;
  margin: 10px auto;
  padding: 6px;
  border-radius: 6px;
}
.availabilityCalender .react-calendar__month-view__days {
  align-items: center;
  justify-content: center;
}


.calendar-header svg {
  background-color: #24A59E66;
  color: white;
  width: 23px;
  height: 24px;
  border-radius: 15px;
  padding: 5px;
}
.selected-date {
  background-color: #24A59E !important;
}

.calendar-header h2 {
  font-size: 14px;
}

.react-calendar .highlight {
  height: 44px !important;
  flex: none !important;
  width: 44px !important;
  background-color: #24A59E !important;
  border-radius: 22px;
  color: white !important;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  align-items: center;
  margin-right: 5px !important;
  justify-content: center
}

.save-btn button {
  width: 327px;
  height: 61px;
  top: 576px;
  left: 114px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  opacity: 1;
  border: none;
}

.save-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.btn-1 {
  background: #24A59E;
  color: white;
  margin-top: 100px;
}

.select-month-btn {
  width: 120px;
  padding: 5px;
  border-radius: 20px;
  background-color: #24A59E;
  border: none;
  color: #fff;
  align-self: center;
}

.btn-2 {
  border-style: solid !important;
  border-width: 2px !important;
  background-color: #FFF;
  border-color: #d3d3d3 !important;
  color: #FF5757;
}

.btn-2-disabled {
  color: #FFF;
  background-color: #d3d3d3;
}

.btn-1-disabled {
  color: #FFF;
  margin-top: 100px;
  background-color: #d3d3d3;
}

.react-calendar__month-view__weekdays__weekday {
  color: #909090;
} 

.react-calendar__navigation__prev2-button{
  padding-left: 85px !important;
}
.react-calendar__navigation__label{
  flex-grow: 0 !important;
}
.lable-container{
  display: inline-block; 
}

.react-calendar__navigation button:nth-child(1),
.react-calendar__navigation button:nth-child(5) {
  display: none !important;
}

.react-calendar__navigation{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #d3d3d3 !important;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
}

.react-calendar__navigation__arrow .react-calendar__navigation__next-button:hover{
background: none !important;
}

.react-calendar__navigation button {
  min-width: fit-content;
}

.react-calendar__navigation__label{
  pointer-events: none;
}

.react-calendar{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

